<template lang="pug">
	.mb-4.tariff(v-if='plans && plans.length > 0')
		.d-flex.justify-content-between.align-items-center.mb-4
			dropdown-status(@onChange='changeStatus')
			span.btn.btn-primary.plus-primary.tariff__btn(@click="$emit('click', $event)")
				span.plus-primary
				span(v-if="!isMobile") {{ $t('promotions.tariff.createEvent.btn') }}
		promotions-card.mb-4(v-for="(card, i) in plansFiltered" :key="`tariff-${i}`" :card="card" :promotions="types.tariff")

		.d-flex.flex-column.pt-md-5.my-5(v-if="plansFiltered.length == 0 && this.selectedStatus == 'ARCHIVE'")
			h1.text-center.mb-2 😢
			span.b1.text-center {{ $t('project.notArchivedRates') }}

		.d-flex.flex-column.pt-md-5.my-5(v-if="plansFiltered.length == 0 && this.selectedStatus == 'ACTIVE'")
			h1.text-center.mb-2 😢
			span.mb-2.b1.text-center {{ $t('project.notActivedPlans') }}
			span.b3.text-center.text-grey {{ $t('project.addFirstRate') }}
</template>

<script>
import DropdownStatus from '@/components/Dropdown/DropdownStatus';
import PromotionsCard from '@/components/Cards/PromotionsCard';
import { mapGetters } from 'vuex';
import { STATUS_ACTIVE } from '@/models/model';
import { PLAN_TABS } from '@/models/project';

export default {
	name: 'Tariff',
	components: {
		DropdownStatus,
		PromotionsCard
	},
	data: () => ({
		selectedStatus: null,
		types: PLAN_TABS
	}),
	computed: {
		...mapGetters({
			plans: 'plans/list'
		}),
		plansFiltered () {
			return _.filter( this.plans, i => this.selectedStatus == STATUS_ACTIVE ? i.isActive() : i.isArchived() );
		}
	},
	methods: {
		changeStatus ( i ) {
			this.selectedStatus = i;
		}
	}
}
</script>

<style lang='scss' scoped>
@import '~@/assets/css/sass/_mixins.scss';

.tariff {
	&__btn {
		min-width: 256px;

		@include respond-below(sm) {
			min-width: 50px;
			box-shadow: none !important;
		}
	}
}
</style>
