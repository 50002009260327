<template lang='pug'>
	.d-flex.flex-column.flex-md-row.overflow-visible.foreground-color.basic-box-shadow.border-rounded.promotions-card(v-if="card" :class="{'free-trial': card.isTrial && card.free}")
		.d-flex.flex-column.flex-md-row.align-items-center.w-100
			.d-flex.align-items-center.pr-md-3.promotions-card__item
				.d-flex.flex-column.w-100.mb-3.mb-md-0
					.d-flex.align-items-center.mb-2
						span.b1.mr-2.promotions-card__name(v-if="card.title") {{ card.title }}
						span.b4.text-primary.promotions-card__discount(v-if="card.discount") -{{ card.discount }}%
						svg.d-block.ml-auto.promotions-card__dots(v-if="isMobile" @click="onOpenPopover" :id="card.title" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none")
							g(fill="#52A7F9" clip-path="url(#a)")
								path(d="M1.5 10.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM9 10.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM16.5 10.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z")
									defs
										clipPath (id="a")
											path(fill="#fff" d="M0 0h18v18H0z")
						b-popover(v-if="isMobile" ref="popoverMenuDots" :target="card.title" :custom-class="customClassCard")
							i.fontello-btn.fontello-btn-primary.fontello-icon-copy.promotions-card__btn-popover(v-if="promotions == types.promoCode && !card.isArchived()" @click="copy(card.title), onClosePopover()")
							i.fontello-btn.fontello-btn-primary.fontello-icon-edit.promotions-card__btn-popover(@click="openModal(promotions)")
							i.fontello-btn.fontello-btn-red.fontello-icon-trash.promotions-card__btn-popover(v-if='!card.isArchived()' @click='openModalRemove(promotions)')
					.d-flex.align-items-center.b3.flex-wrap
						.d-flex.align-items-center
							span.mr-2.text-grey.text-nowrap(v-if="card.startAt && promotions != types.tariff") {{ $t('promotions.from') }} {{ card.startAt.split('T')[0] | dateDDMMMYYDots }}
							span.mr-3.text-grey.text-nowrap(v-if="card.endAt && promotions != types.tariff") {{ $t('promotions.to') }} {{ card.endAt.split('T')[0] | dateDDMMMYYDots }}
							span.mr-3.text-grey(v-if="!card.startAt && !card.endAt && promotions != types.tariff") {{ $t('promotions.noTimeLimit') }}
							router-link.text-primary(
								v-if="card.subscriptionsCount && promotions == types.stock"
								:to='{name: "project_followers", query: {promotion: card.id}}'
								target='_blank') {{ card.subscriptionsCount }} {{ declOfSubscriptions(card.subscriptionsCount) }}
							span.text-grey(v-if="!card.subscriptionsCount && promotions == types.stock") {{ $t('promotions.noSubscriptions') }}
						router-link.text-primary.align-items-center.text-nowrap(
							v-if="promotions == types.promoCode"
							:to='{name: "project_followers", query: {promoCode: card.id}}'
							target='_blank')
							span {{ $t('promotions.usage') }} {{ card.subscriptionsCount }}
							span(v-if="card.capacity != null") /{{ card.capacity + card.subscriptionsCount }}
						.d-flex.flex-column(v-if="promotions == types.tariff")
							span.text-grey
								span(v-html="`${card.price.price()}&nbsp;`")
								span(v-html='card.price.currencyTitle()')
								span(v-html="`&nbsp;${$t('plan.periods.' + card.period.title)}`")
							span.text-primary.mt-2.position-relative.promotions-card__free(v-if="card.isTrial && card.free" v-html="`${$t('promotions.tariff.free')}&nbsp;${$t('plan.freePeriods.' + card.free.title)}`")
				.d-flex.align-items-center
			.d-flex.align-items-center.justify-content-md-center.px-md-3.promotions-card__item
				dropdown-plans(v-if="promotions != types.tariff" :discount="card.discount" :list="card.plans" :id="card.id")
				users-pics(v-else-if='card.hasActiveResources()' :list="card.getResources()" :card='card')
				.w-70.w-xs-100(v-else)
					.mx-md-auto.position-relative
						span.alerts.px-3.justify-content-between.resources-card__item-alert.alert-error
							span {{ $t('promotions.tariff.withoutResources') }}
							span.cursor-pointer.resources-card__tooltip.ml-2(
								:id="!isMobile ? `resourceTooltip-${card.id}` : ''"
								@click="isMobile ? $bvModal.show(`resourceTooltip-${card.id}`) : ''"
							) ?

					modal-tooltip(
						:id="`resourceTooltip-${card.id}`"
						:title="$t('promotions.tariff.tariffNotAvailable')"
						:text="$t('promotions.tariff.withoutResourcesDesc')"
						:textMobile="$t('promotions.tariff.withoutResourcesDesc')"
					)
		.d-flex.align-items-center.pl-md-3.w-30(v-if="!isMobile" :class='{"justify-content-end" : !card.isArchived(), "justify-content-center" : card.isArchived()}')
			i.mx-1.fontello-btn.fontello-btn-primary.fontello-icon-copy(v-if="promotions == types.promoCode && !card.isArchived()" @click="copy(card.title)")
			i.mx-1.fontello-btn.fontello-btn-primary.fontello-icon-edit(@click="openModal(promotions)")
			i.ml-1.fontello-btn.fontello-btn-red.fontello-icon-trash(v-if='!card.isArchived()' @click='openModalRemove(promotions)')

		modal-remove-promotion(
			v-if="promotions == types.tariff"
			:btnClass="card.canBeDeleted ? 'btn-peril' : 'btn-primary'"
			:id="`tariff-${card.id}`"
			:title="$t('promotions.tariff.delete.title')"
			:text="card.canBeDeleted ? $t('promotions.tariff.delete.text') : $t('plan.archivePlanConfirm')"
			:btnText="card.canBeDeleted ? $t('promotions.tariff.delete.btnText') : $t('forms.archive')"
			:item='removeItem')

		modal-remove-promotion(
			v-if="promotions == types.stock"
			:btnClass="card.canBeDeleted ? 'btn-peril' : 'btn-primary'"
			:id="`stock-${card.id}`"
			:title="card.canBeDeleted ? $t('promotions.stock.delete.title') : $t('promotions.stock.archive.title')"
			:text="card.canBeDeleted ? $t('promotions.stock.delete.text') : $t('promotions.stock.archive.text')"
			:btnText="card.canBeDeleted ? $t('promotions.stock.delete.btnText') : $t('promotions.stock.archive.btnText')"
			:item='removeItem')

		modal-remove-promotion(
			v-if="promotions == types.promoCode"
			:btnClass="card.canBeDeleted ? 'btn-peril' : 'btn-primary'"
			:id="`promoCodes-${card.id}`"
			:title="card.canBeDeleted ? $t('promotions.promoCodes.delete.title') : $t('promotions.promoCodes.archive.title')"
			:text="card.canBeDeleted ? $t('promotions.promoCodes.delete.text') : $t('promotions.promoCodes.archive.text')"
			:btnText="card.canBeDeleted ? $t('promotions.promoCodes.delete.btnText') : $t('promotions.promoCodes.archive.btnText')"
			:item='removeItem')

		modal-general(v-if="promotions == types.tariff" :id="`edit-tariff-${card.id}`" :title="$t('promotions.tariff.editEvent.btn')")
			add-promotion(:create="types.tariff" :item="selectedItem" :channels="card.getResources()" :modalId="`edit-tariff-${card.id}`")

		modal-general(v-if="promotions == types.stock" :id="`edit-stock-${card.id}`" :title="$t('promotions.stock.editEvent.btn')")
			add-promotion(:create="types.stock" :item="selectedItem" :plans="allPlans" :modalId="`edit-stock-${card.id}`")

		modal-general(v-if="promotions == types.promoCode" :id="`edit-promo-code-${card.id}`" :title="$t('promotions.promoCodes.editEvent.btn')")
			add-promotion(:create="types.promoCode" :item="selectedItem" :plans="allPlans" :modalId="`edit-promo-code-${card.id}`" @editPromocode="$emit('editPromocode')")
</template>

<script>
import DropdownPlans from '@/components/Dropdown/DropdownPlans';
import RemovePromotion from '@/components/Modals/RemovePromotion';
import ModalGeneral from '@/components/Modals/ModalGeneral';
import AddPromotion from '@/components/Form/AddPromotion';
import UsersPics from '@/components/Common/UsersPics';
import ModalTooltip from '@/components/Modals/ModalTooltip';
import IconClose from "@/components/Common/IconClose";
import { PLAN_TABS } from '@/models/project';
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'PromotionsCard',
	components: {
		DropdownPlans,
		'modal-remove-promotion': RemovePromotion,
		ModalGeneral,
		AddPromotion,
		UsersPics,
		IconClose,
		ModalTooltip
	},
	props: {
		card: {
			default: () => {},
			type: Object
		},
		promotions: {
			default: '',
			type: String
		},
		customClass: {
			default: '',
			type: String
		}
	},
	data: () => ({
		overlay: false,
		selectedItem: null,
		removeItem: null,
		types: PLAN_TABS,
		customClassCard: ''
	}),
	computed: {
		...mapGetters({
			project: 'project/opened',
			plans: 'plans/list'
		}),
		allPlans() {
			return _.filter(this.plans, p => p.isActive() );
		}
	},
	created() {
		window.addEventListener('click', e => {
			if(this.overlay === true) {
				const target = e.target;

				if (!target.closest('.b-popover') && !target.closest('.promotions-card__dots')) {
					this.onClosePopover();
				}
			}
		})

		this.customClassCard = this.customClass;

		if(this.card.capacity) {
			this.customClassCard = 'popover-usage';
		}
	},
	methods: {
		...mapActions({
			getCanBeDeleted: 'project/canBeDeleted'
		}),
		// Функция правильного склонения слов
		declOfNum(n, text_arr) {
			let num = Math.abs(n) % 100;
			let n1 = num % 10;

			if (num > 10 && num < 20) {
				return text_arr[2];
			}
			if (n1 > 1 && n1 < 5) {
				return text_arr[1];
			}
			if (n1 == 1) {
				return text_arr[0];
			}
			return text_arr[2];
		},
		declOfSubscriptions(num) {
			return this.declOfNum(num, [this.$t('promotions.declOfSubscriptions[0]'), this.$t('promotions.declOfSubscriptions[1]'), this.$t('promotions.declOfSubscriptions[2]')]);
		},
		onOpenPopover() {
			if (this.$refs.popoverMenuDots)
				this.$refs.popoverMenuDots.$emit('open');
			document.body.classList.add('overlay');
			this.overlay = true;
		},
		onClosePopover(id) {
			if (this.$refs.popoverMenuDots)
				this.$refs.popoverMenuDots.$emit('close');
			document.body.classList.remove('overlay');
			this.overlay = false;
			this.$root.$emit('bv::show::modal', id)
		},
		openModal(promotion) {
			this.getCanBeDeleted({id: this.project.id});
			this.selectedItem = _.cloneDeep(this.card);
			this.$bvModal.show(`edit-${promotion}-${this.card.id}`);
			this.onClosePopover(`edit-${promotion}-${this.card.id}`);
		},
		openModalRemove ( promotion ) {
			this.removeItem = _.cloneDeep(this.card);
			this.onClosePopover(`${promotion}-${this.card.id}`);
		},
		async copy(text) {
			try {
				await this.$copyText(text);
				this.$notify("success", `😊 &nbsp;&nbsp;&nbsp;${this.$t('promotions.copy')}`);
			} catch (e) {
			}
		},
	},
}
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.promotions-card {
	min-height: 90px;
	padding: 15px 20px;

	@include respond-below(sm) {
		padding: 20px 17px;

		&.free-trial {
			position: relative;
			padding: 43px 17px 20px;

			.promotions-card__free {
				position: absolute !important;
				top: -43px;
				left: -17px;
				right: -17px;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 33px;
				margin: 0 !important;
				border-radius: 8px 8px 0 0;

				&::before {
					left: 31%;
				}
			}
		}
	}

	&__item {
		position: relative;
		width: 50%;

		&::before {
			position: absolute;
			content: '';
			top: 50%;
			right: 0;
			width: 1px;
			height: 60px;
			background-color: var(--border-item-color);
			transform: translateY(-50%);
		}

		@include respond-below(sm) {
			width: 100%;

			&::before {
				content: none;
			}
		}
	}

	&__name {
		line-height: calc(16/16);
		overflow-wrap: anywhere;
	}

	&__dots {
		min-width: 18px;
	}

	&__discount {
		padding: 2px 6px;
		background: var(--primary-bg-color);
		border-radius: 20px;

		@include respond-below(sm) {
			margin-right: 12px;
		}
	}

	&__free {
		padding: 7px 10px 7px  38px;
		font-size: 13px;
		line-height: 14px;
		background: var(--primary-bg-color);
		border-radius: var(--border-radius-rounded);

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 10px;
			width: 18px;
			height: 18px;
			background-image: url('/assets/img/icons/clock.svg');
			transform: translateY(-50%);
		}
	}

	.resources-card__tooltip {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 24px;
		min-height: 24px;
		max-width: 24px;
		max-height: 24px;
		color: var(--brand-error);
		background-color: var(--foreground-color);
		border-radius: 50%;

		svg {
			width: 14px;
			height: 13px;
		}
	}

	&__btn-popover {
		@include respond-below(sm) {
			margin: 5px 0;
		}
	}
}
</style>
