<template lang="pug">
	.mb-4.promo-codes(v-if='list && list.length > 0')
		.d-flex.justify-content-between.align-items-center.mb-4
			dropdown-status(@onChange='changeStatus')
			span.btn.btn-primary.plus-primary.promo-codes__btn(@click="$emit('click', $event)")
				span.plus-primary
				span(v-if="!isMobile") {{ $t('promotions.promoCodes.createEvent.btn') }}
		promotions-card.mb-4(v-for="(card, i) in listFiltered" :key="`promo-codes-${i}`" :card="card" :promotions="types.promoCode" @editPromocode="$emit('editPromocode')" :customClass="card.isArchived() ? 'popover-resources' : ''")

		.d-flex.flex-column.pt-md-5.my-5(v-if="listFiltered.length == 0 && this.selectedStatus == 'ARCHIVE'")
			h1.text-center.mb-2 😢
			span.b1.text-center {{ $t('project.notArchivedPromo') }}

		.d-flex.flex-column.my-5(v-if="listFiltered.length == 0 && this.selectedStatus == 'ACTIVE'")
			h1.text-center.pt-md-5.mb-2 😢
			span.mb-2.b1.text-center {{ $t('project.notArchivedPromo') }}
			span.b3.text-center.text-grey {{ $t('project.addFirstPromo') }}
</template>

<script>
import DropdownStatus from '@/components/Dropdown/DropdownStatus';
import PromotionsCard from '@/components/Cards/PromotionsCard';
import {STATUS_ACTIVE} from '@/models/model'
import { mapGetters } from 'vuex';
import {PLAN_TABS} from '@/models/project'

export default {
	name: 'PromoCodes',
	components: {
		DropdownStatus,
		PromotionsCard
	},
	data: () => ({
		selectedStatus	: null,
		types 			: PLAN_TABS
	}),
	computed: {
		...mapGetters({
			project : 'project/opened',
			list 	: 'project/promocodes'
		}),

		listFiltered () {
			return _.filter( this.list, i => this.selectedStatus == STATUS_ACTIVE ? i.isActive() : i.isArchived() );
		},
	},
	created() {

	},
	methods: {
		changeStatus ( i ) {
			this.selectedStatus = i;
		},
	},
}
</script>
<style lang='scss' scoped>
@import '~@/assets/css/sass/_mixins.scss';

.promo-codes {
	&__btn {
		min-width: 256px;

		@include respond-below(sm) {
			min-width: 50px;
			box-shadow: none !important;
		}
	}
}
</style>
